import { Application, Controller } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import IntersectionObserverUtil from '@root/lib/intersection-observer-util';
import IOLazyLoader from '@javascript/library/io-lazy-loader';
const application = Application.start();
const ioUtil = new IntersectionObserverUtil();
Object.defineProperties(application, {
    ioUtil: {
        value: ioUtil,
        writable: false,
    },
    ioLazyLoader: {
        value: new IOLazyLoader(ioUtil),
        writable: false,
    },
});
function loadFromContext(context) {
    application.load(definitionsFromContext(context));
}
export { application, Controller, definitionsFromContext, loadFromContext, };
